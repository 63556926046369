import React from 'react'
import Layout from '../components/Layout'

export default () => (
  <Layout
    langKey="en"
    slug="/"
  >
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container has-text-centered is-size-8">
          <h1
            className="has-text-vivid-red has-text-weight-bold"
            style={{padding: '1rem'}}
          >
            Thank you for your purchase!
          </h1>
        </div>
      </div>
    </section>
  </Layout>
)
